import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider as AntSlider } from 'antd';
import Props from 'utils/proptypes';
import Linkify from 'react-linkify';
import classNames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class Slider extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onIncomplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.number,
      type: Props.questionnaire.answerType,
    }),
    disabled: PropTypes.bool,
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
    extension: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        valueString: PropTypes.string,
      }),
    ),
    flags: PropTypes.object,
  };

  static defaultProps = {
    answer: {},
    disabled: false,
    isAnswerType: false,
    readOnly: false,
  };

  state = {
    value: this.props.answer.value,
  };

  componentDidMount() {
    const { onAnswer, id, onComplete, required } = this.props;
    const { value } = this.state;
    if (typeof value === 'undefined') {
      onAnswer(id, { value: 0, valueType: 'integer' });
      if (!required) {
        onComplete();
      }
    }
  }

  parseSliderOptions() {
    const displayOptions = {
      slider: {
        min: null,
        max: null,
      },
    };

    try {
      if (this.props.extension && Array.isArray(this.props.extension)) {
        this.props.extension.forEach((ext) => {
          if (
            ext.url ===
            'http://webpt.com/fhir/StructureDefinition/questionnaireItemDisplaySliderMin'
          ) {
            displayOptions.slider.min = ext.valueString || '';
          }
          if (
            ext.url ===
            'http://webpt.com/fhir/StructureDefinition/questionnaireItemDisplaySliderMax'
          ) {
            displayOptions.slider.max = ext.valueString || '';
          }
        });
      }
    } catch (error) {
      console.error('Error parsing slider options:', error);
    }

    return displayOptions;
  }

  handleChange = (value) => {
    const { onAnswer, id, onComplete, onIncomplete, required } = this.props;
    onAnswer(id, { value, valueType: 'integer' });
    this.setState({
      value,
    });
    if (required && value === undefined) {
      onIncomplete();
    } else {
      onComplete();
    }
  };

  renderSlider = () => {
    const { disabled } = this.props;

    const displayOptions = this.parseSliderOptions();
    const min = displayOptions?.slider?.min;
    const max = displayOptions?.slider?.max;

    const sliderProps = {
      onAfterChange: this.handleChange,
      defaultValue: this.state.value,
      min: 0,
      max: 10,
      step: 1,
      marks: { 0: 0, 5: 5, 10: 10 },
    };

    const mipsFlagEnabled = this.props.flags?.['ketKeet2519MipsEnhancements'];

    return (
      <>
        <AntSlider {...sliderProps} disabled={disabled} />
        {mipsFlagEnabled && (min !== undefined || max !== undefined) && (
          <div className="flex flex-row items-center justify-between">
            <p>{min}</p>
            <p>{max}</p>
          </div>
        )}
      </>
    );
  };

  render() {
    const { text, isAnswerType, readOnly } = this.props;
    return (
      <div
        data-testid="slider-question"
        className="ma3 w-80 center overflow-x-hidden"
      >
        <p
          className={classNames('b f5', isAnswerType ? 'tl' : 'tc')}
          style={{ overflowWrap: 'break-word' }}
        >
          <Linkify properties={{ target: '_blank' }}>
            {text}
            {readOnly && ':'}
            {isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <div className="md-cell--12 center">{this.renderSlider()}</div>
      </div>
    );
  }
}

export default withLDConsumer()(Slider);
